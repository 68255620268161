import { Text, Button, Box } from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { FaAndroid } from "react-icons/fa";
import { IoLogoAndroid } from "react-icons/io";
import { LocalizationContext } from "../../../hooks/use-localization";
import { detectAndroid } from "../../../utils/detect-android";
import { logger } from "../../../utils/logger";

// Define a type for the beforeinstallprompt event
interface BeforeInstallPromptEvent extends Event {
  prompt: () => void;
  userChoice: Promise<{ outcome: "accepted" | "dismissed"; platform: string }>;
}

export default function PwaPromptAndroid() {
  const { l } = useContext(LocalizationContext);
  const [installPrompt, setInstallPrompt] = useState<BeforeInstallPromptEvent | null>(null);
  const [isAndroid, setIsAndroid] = useState(false);

  useEffect(() => {
    // Check if the user is on an Android device
    setIsAndroid(detectAndroid());

    const handleBeforeInstallPrompt = (e: BeforeInstallPromptEvent) => {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault();
      // Save the event for later (to show the install prompt)
      setInstallPrompt(e);
    };

    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt as EventListener);

    return () => {
      window.removeEventListener("beforeinstallprompt", handleBeforeInstallPrompt as EventListener);
    };
  }, []);

  const handleInstallClick = () => {
    if (installPrompt) {
      // Show the install prompt
      installPrompt.prompt();
      // Wait for the user to respond to the prompt
      installPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          logger.info("User accepted the install prompt");
        } else {
          logger.info("User dismissed the install prompt");
        }
        setInstallPrompt(null);
      });
    }
  };

  return (
    <>
      {isAndroid && installPrompt && (
        <Button mb={7} colorScheme={"brand.green"} onClick={handleInstallClick}>
          <Box mr={3} fontSize="20px">
            <FaAndroid />
          </Box>
          <Text>{l["components.pwaPromptAndroid.title"]}</Text>
        </Button>
      )}
    </>
  );
}
