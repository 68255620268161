import { Image, Text, Flex, Spinner, Avatar, Button, border } from "@chakra-ui/react";
import { useContext, useState, useEffect } from "react";
import { useCharacterFromVoice } from "../../hooks/use-character-from-voice";
import { LocalizationContext } from "../../hooks/use-localization";
import { imageFromId } from "../../utils/image-from-id";
import { siteConfig } from "../../configuration/config";
import { useRouter } from "next/router";
import { interpolateString } from "../../utils/interpolate-string";

export default function DailySession() {
  const router = useRouter();
  const { l } = useContext(LocalizationContext);
  const character = useCharacterFromVoice();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const handleRouteChange = () => {
      setIsLoading(false);
    };

    router.events.on("routeChangeComplete", handleRouteChange);
    router.events.on("routeChangeError", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
      router.events.off("routeChangeError", handleRouteChange);
    };
  }, [router.events]);

  if (!character) {
    return <Spinner />;
  }

  const goToChat = () => {
    setIsLoading(true);
    router.push(`/app/chat?free=true`);
  };

  const config = siteConfig.templates?.find((t) => t.type === "free-talk");

  return (
    <Flex
      borderRadius={"8px"}
      flexDir="column"
      bgColor={config?.backgroundColor || "#1d101c"}
      color={config?.descriptionTextColor || "white"}
      border={config?.borderColor && `1px solid ${config?.borderColor}`}
      h="full"
      p={7}
      position="relative"
      overflow={"hidden"}
    >
      <Flex
        mt={{ base: 0, lg: 6 }}
        flexDir="column"
        alignItems={"center"}
        justifyContent="center"
        zIndex="2"
      >
        <Avatar src={imageFromId(character.id)} mb={3} size="lg" />
        <Text fontWeight={"bold"} fontSize="2xl">
          {l["dashboard.template.dailySession.title"]}
        </Text>
        <Text fontSize="xs" fontStyle={"italic"}>
          {interpolateString(l["dashboard.template.dailySession.with"], {
            tutorName: character.avatarName,
          })}
        </Text>
      </Flex>
      <Flex zIndex="2" flexDir="column" alignItems={"center"} justifyContent="center">
        <Text maxW="350px" textAlign="center" my={{ base: 3, lg: 8 }} fontSize="sm">
          {l["dashboard.template.dailySession.description"]}
        </Text>

        <Button
          bg={config?.buttonBackgroundColor || "brand.yellow.400"}
          color={config?.buttonTextColor || "black"}
          onClick={goToChat}
          isLoading={isLoading}
          opacity={1}
          _disabled={{
            opacity: 1,
            cursor: "not-allowed",
          }}
          _hover={{
            backgroundColor: "brand.gray.300",
            color: "#000",
          }}
        >
          {l["dashboard.template.dailySession.button"]}
        </Button>
      </Flex>

      {config?.backgroundImagePath && (
        <Image
          objectFit="cover"
          maxW={{ base: "100%" }}
          h={{ lg: "full" }}
          w={{ base: "full", lg: "auto" }}
          src={"/templates/talk.jpeg"}
          alt={l["dashboard.template.dailySession.title"]}
          pos={"absolute"}
          borderRadius={"8px"}
          left="0"
          top="0"
          opacity={0.25}
          _before={{
            content: "''",
            position: "absolute",
          }}
        />
      )}
    </Flex>
  );
}
