import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { siteConfig } from "../../configuration/config";
import { useLocalStorage } from "../../hooks/use-local-storage";

export default function ChinaModal() {
  const [isOpen, setIsOpen] = useState(false);
  const modalSeenStorage = useLocalStorage<string>("seen-china-website-modal", "false");

  useEffect(() => {
    const isChinese = window.navigator.language.startsWith("zh");
    const notChinaSite = window.location.host !== "cn.talkio.ai";

    if (
      siteConfig.isDefault &&
      notChinaSite &&
      !modalSeenStorage.isLoadingStorage &&
      modalSeenStorage.storageData === "false" &&
      isChinese
    ) {
      setIsOpen(true);
      modalSeenStorage.setStorageData("true");
    }
  }, [modalSeenStorage]);

  const onClose = () => {
    setIsOpen(false);
  };

  return (
    <Modal size={"xl"} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent px={5} py={5}>
        <ModalHeader textAlign={"center"}>欢迎来到 Talkio</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          您的浏览器语言似乎设置为中文。是否要切换到针对中国使用优化的中文服务器？
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" onClick={onClose}>
            取消
          </Button>
          <Button colorScheme="brand.green" mr={3} as="a" href="https://cn.talkio.ai/app">
            访问网站
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
