type StreakAchievement = [number, string, string];

const streakAchievements: StreakAchievement[] = [
  [1, "🌱", "Starting the journey"],
  [3, "🌟", "Three days of consistency"],
  [4, "🌟", "Four days of consistency"],
  [5, "🔥", "A solid five-day streak"],
  [6, "🔥", "A solid six-day streak"],
  [7, "🚀", "A week of learning"],
  [8, "🚀", "More than a week of learning"],
  [10, "💪", "Double digits!"],
  [14, "📘", "Two weeks of knowledge"],
  [20, "🎉", "Hitting the 20-day mark"],
  [25, "🏆", "Quarter of a century!"],
  [30, "🌍", "A month of dedication"],
  [40, "🦉", "Forty days of wisdom"],
  [50, "🦄", "Halfway to a hundred"],
  [60, "⏳", "Two months of persistence"],
  [75, "🌈", "Three-quarters of a century"],
  [90, "🌔", "Three months of commitment"],
  [100, "💎", "Century of learning"],
  [120, "🔮", "Four months, magic touch!"],
  [150, "🏰", "150 days, a fortress of knowledge"],
  [180, "🌅", "Half a year of enlightenment"],
  [200, "🛸", "200 days, soaring high"],
  [250, "👑", "Quarter of a millennium!"],
  [300, "💫", "300 days, a shining example"],
];

// Usage Example:
// Find the appropriate emoji and explanation for a given streak length
export const findAchievementForStreak = (streakLength?: number): [string, number] => {
  if (!streakLength) {
    return [streakAchievements[0][1], streakAchievements[0][0]];
  }

  let achievement: [string, number] = ["🎖️", 1]; // Default if no specific match is found
  for (const achievementEntry of streakAchievements) {
    if (streakLength >= achievementEntry[0]) {
      achievement = [achievementEntry[1], achievementEntry[0]];
    } else {
      break;
    }
  }
  return achievement;
};

// // Example: Get the emoji and explanation for a 10-day streak
// const [streakEmoji, streakExplanation] = findAchievementForStreak(10);
// console.log(streakEmoji, streakExplanation); // Output: 💪 Double digits!
