type ConversationAchievement = [number, string, string];

const conversationAchievements: ConversationAchievement[] = [
  [1, "🌟", "Getting started!"],
  [3, "💬", "Three chats already!"],
  [5, "📘", "Five conversations deep!"],
  [10, "🎉", "Ten discussions, well done!"],
  [15, "🚀", "15 conversations! Finding your rhythm!"],
  [16, "🚀", "Finding your rhythm!"],
  [20, "🔍", "20 deep dives into learning!"],
  [21, "🔍", "Deep dives into learning!"],
  [30, "🌈", "30 chats! The world is brighter!"],
  [31, "🌈", "The world is brighter!"],
  [40, "🌱", "40 conversations! Growing knowledge!"],
  [41, "🌱", "Growing knowledge!"],
  [50, "🔥", "Half a century of chats!"],
  [75, "💡", "75 enlightening talks!"],
  [100, "💎", "A hundred conversations! Truly committed!"],
  [150, "🏆", "150 chats! A milestone of dedication!"],
  [200, "🌍", "200 conversations! World-class communicator!"],
  [250, "🎭", "250 chats! Master of dialogues!"],
  [300, "🔮", "300 conversations! Future is bright!"],
  [400, "🛸", "400 chats! Out of this world!"],
  [500, "🦄", "500 conversations! Legendary!"],
  [750, "🌌", "750 chats! Galaxy of knowledge!"],
  [1000, "🏰", "1000 conversations! Kingdom of wisdom!"],
  [1500, "👑", "1500 discussions! Royalty of conversation!"],
  [2000, "💫", "2000 chats! Shooting star of dialogue!"],
];

export const findAchievementForConversations = (conversationCount?: number): [string, number] => {
  if (!conversationCount) {
    return [conversationAchievements[0][1], conversationAchievements[0][0]];
  }

  let achievement: [string, number] = ["🏅", 0];
  for (const achievementEntry of conversationAchievements) {
    if (conversationCount >= achievementEntry[0]) {
      achievement = [achievementEntry[1], achievementEntry[0]];
    } else {
      break;
    }
  }
  return achievement;
};
