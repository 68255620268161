export function isPWA(): boolean {
  // Check if window and navigator are defined to ensure SSR compatibility
  if (typeof window !== "undefined" && typeof navigator !== "undefined") {
    const navigatorExtended = navigator as Navigator & { standalone?: boolean };
    return (
      window.matchMedia("(display-mode: standalone)").matches ||
      navigatorExtended.standalone ||
      document.referrer.includes("android-app://")
    );
  }

  // Return false or a default value when not in a browser environment
  return false;
}
