import {
  Alert,
  AlertDescription,
  AlertTitle,
  Box,
  CloseButton,
  Flex,
  Text,
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { BsPlusSquare } from "react-icons/bs";
import { IoShareOutline } from "react-icons/io5";
import { siteConfig } from "../../../configuration/config";
import { useLocalStorage } from "../../../hooks/use-local-storage";
import { LocalizationContext } from "../../../hooks/use-localization";
import { detectIOS } from "../../../utils/detect-ios";
import { interpolateString } from "../../../utils/interpolate-string";
import { isPWA } from "../../../utils/is-pwa";

export default function PwaPromptIOS() {
  const { l } = useContext(LocalizationContext);
  const [visible, setVisible] = useState(false);
  const { storageData, setStorageData, isLoadingStorage } = useLocalStorage<{
    status: "first-load" | "show" | "hide";
  }>("pwa-prompt");

  useEffect(() => {
    if (isLoadingStorage) {
      return;
    }

    if (!storageData) {
      setStorageData({ status: "first-load" });
      return;
    }

    if (storageData.status === "first-load" && detectIOS() && !isPWA()) {
      setStorageData({ status: "show" });
    } else if (storageData.status === "show") {
      setVisible(true);
    } else {
      setVisible(false);
    }
  }, [storageData, isLoadingStorage, setStorageData]);

  const onClose = () => {
    setVisible(false);
    setStorageData({ status: "hide" });
  };

  if (!visible) {
    return null;
  }

  return (
    <Alert justifyContent={"space-between"} status="success" w="full" mb={5} borderRadius={5}>
      <Box>
        <AlertTitle>
          {interpolateString(l["components.pwaPromptIos.title"], {
            app: siteConfig.serviceName,
          })}
        </AlertTitle>
        <AlertDescription fontSize="sm">
          <Flex mt="2" alignItems={"center"}>
            <Box w="25px">
              <IoShareOutline fontSize="18px" />
            </Box>
            <Text>{l["components.pwaPromptIos.step1"]}</Text>
          </Flex>
          <Flex mt="2" alignItems={"center"}>
            <Box w="25px" ml={"3px"}>
              <BsPlusSquare fontSize="13px" />
            </Box>
            <Text>{l["components.pwaPromptIos.step2"]}</Text>
          </Flex>
          <Text mt={2}>{l["components.pwaPromptIos.step3"]}</Text>
        </AlertDescription>
      </Box>
      <CloseButton
        alignSelf="flex-start"
        position="relative"
        right={-1}
        top={-1}
        onClick={onClose}
      />
    </Alert>
  );
}
