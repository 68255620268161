import useSWR from "swr";
import { ConversationHistory } from "../pages/api/conversations/history";
import { AppError } from "../utils/app-error";

export async function fetchConversation<T>(url: string): Promise<T> {
  try {
    const res = await fetch(url);
    const conversation: T = await res.json();

    return conversation;
  } catch (err) {
    throw AppError.fromUnknownError(err);
  }
}

export function useConversationHistory() {
  const response = useSWR<ConversationHistory, AppError>(
    `/api/conversations/history`,
    fetchConversation,
  );

  return {
    conversationHistory: response.data,
    conversationHistoryError: response.error,
    conversationHistoryIsLoading: response.isLoading,
  };
}
