import { Flex, Box } from "@chakra-ui/react";
import { useContext } from "react";
import { LocalizationContext } from "../hooks/use-localization";

interface Props {
  count: number;
  label: string;
  statementKey: string;
  statementScore: number;
  statementEmoticon: string;
  backgroundColor: string;
  color: string;
}

export default function Achievement(props: Props) {
  const { l } = useContext(LocalizationContext);
  const statement =
    l[(`dashboard.learningStreaks.${props.statementKey}` + props.statementScore) as keyof typeof l];

  return (
    <Flex
      flexDir="column"
      backgroundColor={props.backgroundColor}
      color={props.color}
      borderRadius={10}
      p={7}
    >
      <Box fontSize={{ base: "50px", "2xl": "60px" }} fontWeight={"300"} lineHeight={0.7}>
        {props.count}
      </Box>

      <Box fontSize={{ base: "18px", "2xl": "22px" }} mt={3} fontWeight={"bold"}>
        {props.label}
      </Box>
      <Box
        letterSpacing={1.6}
        fontSize={{ base: "11px", "2xl": "13px" }}
        fontWeight={"bold"}
        color="gray.700"
      >
        {statement} {props.statementEmoticon}
      </Box>
    </Flex>
  );
}
