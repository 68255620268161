import { formatDistance, formatDuration, intervalToDuration } from "date-fns";
import { enUS, zhCN, da, pl, pt } from "date-fns/locale";
import { pad } from "./timestamp";

/**
 * @deprecated
 */
export function readableDate(d?: Date): string {
  const date = d ? new Date(d) : new Date();
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hours = pad(date.getHours() + 1);
  const minutes = pad(date.getMinutes());

  return `${pad(year)}-${pad(month)}-${pad(day)} ${hours}:${minutes}`;
}

export function dateDistance(date: Date, langCode = "en-US") {
  const shortCode = langCode.split("-")[0];
  const locales: Record<typeof shortCode, any> = {
    en: enUS,
    zh: zhCN,
    da: da,
    pl: pl,
    pt: pt,
  };

  let l = locales[shortCode];

  if (!l) {
    l = enUS;
  }

  return formatDistance(date, new Date(), {
    addSuffix: true,
    locale: l,
  });
}

export function formatDate(date: Date, locale = "en-US"): string {
  const options: Intl.DateTimeFormatOptions = {
    weekday: "long",
    month: "long",
    day: "numeric",
  };

  return new Intl.DateTimeFormat(locale, options).format(date);
}

export function readableTimeFromHours(hours: number): string {
  // Convert hours to milliseconds
  const totalMinutes = Math.floor(hours * 60);
  const duration = intervalToDuration({
    start: 0,
    end: totalMinutes * 60 * 1000,
  });

  const hoursStr = duration.hours?.toString().padStart(2, "0") || "00";
  const minutesStr = duration.minutes?.toString().padStart(2, "0") || "00";

  return `${hoursStr}:${minutesStr}`;
}
