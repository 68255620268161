import { Box, Button, Flex } from "@chakra-ui/react";
import { useContext } from "react";
import { LocalizationContext } from "../hooks/use-localization";

interface Props {
  onNextClick: () => void;
  onPrevClick: () => void;
  showNext: boolean;
  showPrev: boolean;
  backgroundColor?: string;
  color?: string;
  borderColor?: string;
  size?: string;
}

export default function NextPrevButtons(props: Props) {
  const { l } = useContext(LocalizationContext);
  const borderColor = props.borderColor || "ghost";
  const backgroundColor = props.backgroundColor || "transparent";
  const color = props.color || "ghost";

  return (
    <Flex justifyContent={"space-between"}>
      {props.showPrev ? (
        <Button
          id="prev-button"
          variant="outline"
          borderColor={borderColor}
          color={color}
          backgroundColor={backgroundColor}
          _hover={{ backgroundColor: "rgba(255,255,255,0.2)" }}
          onClick={props.onPrevClick}
          size={props.size ?? "lg"}
        >
          {l ? l["app.previous"] : "Previous"}
        </Button>
      ) : (
        <Box></Box>
      )}

      {props.showNext ? (
        <Button
          id="next-button"
          variant="outline"
          color={color}
          borderColor={borderColor}
          backgroundColor={backgroundColor}
          _hover={{ backgroundColor: "rgba(255,255,255,0.2)" }}
          onClick={props.onNextClick}
          size={props.size ?? "lg"}
        >
          {l ? l["app.next"] : "Next"}
        </Button>
      ) : (
        <Box></Box>
      )}
    </Flex>
  );
}
